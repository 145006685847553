import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { CLIENT_SITE_URL, PAYPAL_CLIENT_ID, PAYPAL_PLAN_ID } from '../../Config'

const PayPalSubscription = (): JSX.Element => {
  console.log(
    'window.location.href',
    `${window.location.protocol}//${window.location.host}`
  )
  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID,
        intent: 'subscription',
        vault: true,
        currency: 'USD'
      }}
    >
      <PayPalButtons
        createSubscription={async (data, actions) => {
          console.log('Inside createSubscription', data, actions)
          const sub = await actions.subscription.create({
            plan_id: PAYPAL_PLAN_ID,
            application_context: {
              return_url: `${window.location.protocol}//${window.location.host}/payment-method/success`,
              cancel_url: `${window.location.protocol}//${window.location.host}/payment-method/error`
            }
          })
          console.log('sub', sub, {
            plan_id: PAYPAL_PLAN_ID,
            application_context: {
              return_url: CLIENT_SITE_URL + '/payment-method/success',
              cancel_url: CLIENT_SITE_URL + '/payment-method/error'
            }
          })
          return sub
        }}
        onApprove={async (data, actions) => {
          alert(`Subscription successful! ID: ${data.subscriptionID as string}`)
        }}
        onError={(err) => {
          console.error('Subscription creation error: ', err)
        }}
      />
    </PayPalScriptProvider>
  )
}

export default PayPalSubscription
